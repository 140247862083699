import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const unstyledButton = css`
  background-color: rgba(0, 0, 0, 0);
  text-align: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  border: 0;
`;

export const UnstyledButton = props => {
  const { children } = props;

  return (
    <button type="button" css={unstyledButton} {...props}>
      {children}
    </button>
  );
};

UnstyledButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

UnstyledButton.defaultProps = {
  className: 'OmsUnstyledButton',
};

export default UnstyledButton;
