import React from 'react';
import PropTypes from 'prop-types';

export const Button = props => {
  const { children } = props;

  return (
    <button type="button" {...props}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Button.defaultProps = {
  className: 'OmsButton',
};

export default Button;
