import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Flex, Box } from '@rebass/emotion';
import { I18n } from '@lingui/react';
import { Trans, t } from '@lingui/macro';
import { formatNumber, formatInteger } from '@oms/utils';
import ReactToPrint from 'react-to-print';
import { decimalPlaces, fundTransferCodes, mapType } from './utils';
import * as styles from './styles';

/**
 * **This component is part of the trading components package and requires the corresponding license**
 *
 * Component showing transaction details for an order including dates, accounts, volume, price, contract notes, and total.
 * Used e.g when a user clicks a transactionId in `TransactionsArchive`.
 *
 * Expected data-structure is found in prop-description.
 *
 * This component is used by the `OrderComponent` to display details
 * fetched with `OrderFetcher`, but can also be used by itself by providing it your own data.
 *
 * @see See [OrderComponent](/#!/OrderComponent) for a composition of the fetcher- and details-components.
 * @see See [OrderFetcher](/#!/OrderFetcher) for the fetcher-component.
 * @since 1.4.0
 */
export class OrderDetails extends React.Component {
  render() {
    const {
      data = {},
      className,
      customerId,
      dateFormat,
      instrumentType,
    } = this.props;
    const { createdDate, transactionData = {}, contractNotes = [] } = data;

    const sortByExecutedDateAsc = (a, b) => {
      const firstDate = moment(a.executedDate).format('x');
      const secondDate = moment(b.executedDate).format('x');
      if (firstDate > secondDate) return 1;
      if (firstDate < secondDate) return -1;
      return 0;
    };

    const {
      instrumentName,
      isin,
      transactionId,
      bankAccount,
      executedDate,
      rateDate,
      settlementDate,
      transactionFee,
      stockExchangeId,
      tradeType,
      fullName,
      volume,
      price,
      cashAccount,
      currency,
      fundCurrency,
      priceLocal,
      currencyRate,
      orderCreatedDate,
      transactionCode,
    } = transactionData;

    const isFund = instrumentType === 'fund';
    const isTransfer = fundTransferCodes.includes(transactionCode);

    const pricePerShare = isFund ? priceLocal : price;
    const orderDate = isFund ? orderCreatedDate : createdDate;
    const hideBankAccount = isFund && tradeType === 'BUY';

    const getTotalPrice = () => {
      if (tradeType === 'BUY') {
        return volume * pricePerShare + transactionFee;
      }
      if (tradeType === 'SELL') {
        return volume * pricePerShare - transactionFee;
      }
      return '-';
    };

    return (
      <I18n>
        {({ i18n }) => {
          const trans = key => (i18n ? i18n._(key) : key.id);
          return (
            <Flex
              flexWrap="wrap"
              p={[12, 24]}
              css={styles.orderDetails}
              className={className}
              data-testid="OrderDetails"
              ref={ref => {
                // eslint-disable-next-line
                this.componentRef = ref;
              }}
            >
              <Box width={[1, 'auto']} data-testid="header">
                <h2>
                  <Trans>Transaction no.</Trans> {transactionId || '-'}
                </h2>

                <h3>{fullName}</h3>
              </Box>
              <Box ml="auto" width={[1, 'auto']} data-testid="headerTable">
                <Flex justifyContent="flex-end">
                  <ReactToPrint
                    trigger={() => (
                      <button type="button" className="printButton">
                        <i className="fa fa-print" /> <Trans>Print</Trans>
                      </button>
                    )}
                    content={() => {
                      // eslint-disable-next-line
                      return this.componentRef;
                    }}
                    removeAfterPrint
                  />
                </Flex>
                <table css={styles.headerTable}>
                  <tbody>
                    {!isTransfer && (
                      <tr>
                        <th>
                          <Trans>Order date</Trans>:
                        </th>
                        <td>
                          {orderDate
                            ? moment(orderDate).format(dateFormat)
                            : '-'}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th>
                        <Trans>Executed date</Trans>:
                      </th>
                      <td>
                        {executedDate
                          ? moment(executedDate).format(dateFormat)
                          : '-'}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Trans>Settlement date</Trans>:
                      </th>
                      <td>
                        {settlementDate
                          ? moment(settlementDate).format(dateFormat)
                          : '-'}
                      </td>
                    </tr>
                    {isFund && (
                      <tr>
                        <th>
                          <Trans>Rate date</Trans>:
                        </th>
                        <td>
                          {rateDate ? moment(rateDate).format(dateFormat) : '-'}
                        </td>
                      </tr>
                    )}
                    {customerId && (
                      <tr>
                        <th>
                          <Trans>Customer no.</Trans>:
                        </th>
                        <td>{customerId}</td>
                      </tr>
                    )}
                    {!hideBankAccount && (
                      <tr>
                        <th>
                          <Trans>Bank account</Trans>:
                        </th>
                        <td>{isFund ? cashAccount : bankAccount}</td>
                      </tr>
                    )}
                    <tr>
                      <th>
                        <Trans>Order type</Trans>:
                      </th>
                      <td>{mapType(tradeType, transactionCode)}</td>
                    </tr>
                    {!isFund && (
                      <tr>
                        <th>
                          <Trans>Exchange</Trans>:
                        </th>
                        <td>Oslo Børs ({stockExchangeId})</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Box>

              <Box
                mt={[24, 48]}
                width={1}
                css={styles.detailsWrapper}
                data-testid="detailsTable"
              >
                <table css={styles.detailsTable}>
                  <thead>
                    <tr>
                      {!!contractNotes.length && (
                        <th>
                          <Trans>Executed</Trans>
                        </th>
                      )}
                      <th>
                        <Trans>Name</Trans>
                      </th>
                      <th className="number">
                        {isFund ? (
                          <Trans>Shares</Trans>
                        ) : (
                          <Trans>Quantity</Trans>
                        )}
                      </th>
                      <th className="number">
                        {isFund ? (
                          <Trans>
                            Price per share in {fundCurrency || 'NOK'}
                          </Trans>
                        ) : (
                          <Trans>Price</Trans>
                        )}
                      </th>
                      {isFund && (
                        <th className="number">
                          <Trans>Currency rate</Trans>
                        </th>
                      )}
                      <th className="number">
                        {isFund ? (
                          <Trans>Total price in {currency}</Trans>
                        ) : (
                          <Trans>Total price</Trans>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!!contractNotes.length &&
                      contractNotes
                        .sort(sortByExecutedDateAsc)
                        .map(contractNote => (
                          <tr key={contractNote.transactionId}>
                            <td data-label={trans(t`Executed`)}>
                              {contractNote.executedDate
                                ? moment(contractNote.executedDate).format(
                                    `LT ${dateFormat}`,
                                  )
                                : '-'}
                            </td>
                            <td data-label={trans(t`Name`)}>
                              {contractNote.instrumentName}
                            </td>
                            <td
                              data-label={trans(t`Quantity`)}
                              className="number"
                            >
                              {formatInteger(contractNote.volume || '0')}
                            </td>
                            <td data-label={trans(t`Price`)} className="number">
                              {formatNumber(contractNote.price || '0', 2)}
                            </td>
                            <td
                              data-label={trans(t`Total price`)}
                              className="number"
                            >
                              {formatNumber(
                                contractNote.volume * contractNote.price || '0',
                                2,
                              )}
                            </td>
                          </tr>
                        ))}
                    {!contractNotes.length && (
                      <tr>
                        <td data-label={trans(t`Name`)}>{instrumentName}</td>
                        <td
                          data-label={trans(isFund ? t`Shares` : t`Quantity`)}
                          className="number"
                        >
                          {formatNumber(
                            volume || '0',
                            decimalPlaces(volume, 8),
                          )}
                        </td>
                        <td
                          data-label={trans(
                            isFund
                              ? t`Price per share in ${fundCurrency || 'NOK'}`
                              : t`Price`,
                          )}
                          className="number"
                        >
                          {formatNumber(price || '0', 2)}
                        </td>
                        {isFund && (
                          <td
                            data-label={trans(t`Currency rate`)}
                            className="number"
                          >
                            {formatNumber(
                              currencyRate,
                              decimalPlaces(currencyRate, 6),
                            )}
                          </td>
                        )}
                        <td
                          data-label={trans(
                            isFund
                              ? t`Total price in ${currency}`
                              : t`Total price`,
                          )}
                          className="number"
                        >
                          {formatNumber(volume * pricePerShare || '0', 2)}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Box>
              <Flex
                justifyContent="space-between"
                width={1}
                flexWrap="wrap"
                mt={24}
              >
                <Flex flexDirection="column">
                  <strong>{instrumentName}</strong>
                  ISIN: {isin}
                </Flex>
                <Box width={[1, 'auto']} data-testid="footerTable">
                  <table css={styles.footerTable}>
                    <tbody>
                      <tr>
                        <th>
                          {isFund ? (
                            <Trans>Fee</Trans>
                          ) : (
                            <Trans>Transaction fee</Trans>
                          )}
                          :
                        </th>
                        <td className="number">
                          {formatNumber(transactionFee || '0', 2)}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Trans>Amount</Trans>:
                        </th>
                        <td className="number">
                          {formatNumber(volume * pricePerShare || '0', 2)}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          {tradeType === 'BUY' ? (
                            <Trans>To pay</Trans>
                          ) : (
                            <Trans>Total received</Trans>
                          )}
                          :
                        </th>
                        <td className="number">
                          {formatNumber(getTotalPrice() || '0', 2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Box>
              </Flex>
            </Flex>
          );
        }}
      </I18n>
    );
  }
}

OrderDetails.propTypes = {
  /**
   * Data object containing transaction details.
   */
  data: PropTypes.shape({
    createdDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    transactionData: PropTypes.shape({
      instrumentName: PropTypes.string,
      isin: PropTypes.string,
      transactionId: PropTypes.number,
      bankAccount: PropTypes.string,
      executedDate: PropTypes.string,
      rateDate: PropTypes.string,
      settlementDate: PropTypes.string,
      transactionFee: PropTypes.number,
      stockExchangeId: PropTypes.string,
      tradeType: PropTypes.string,
      fullName: PropTypes.string,
      volume: PropTypes.number,
      price: PropTypes.number,
      cashAccount: PropTypes.string,
      currency: PropTypes.string,
      fundCurrency: PropTypes.string,
      priceLocal: PropTypes.number,
      currencyRate: PropTypes.number,
      orderCreatedDate: PropTypes.string,
    }),
    contractNotes: PropTypes.array,
  }).isRequired,
  /**
   * Classname applied to the component
   */
  className: PropTypes.string,
  /**
   * Specifies what kind of instrument, this affects fields like rate date and stock exhange ID.
   */
  instrumentType: PropTypes.oneOf(['fund', 'stock', 'fund-and-stock']),
  /**
  /**
   * If provided shows customer number in OrderDetails header
   */
  customerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * A momentjs format string that the component will use to display dates.
   *
   * The default will display a localized short format based on the configured
   * momentjs locale.
   */
  dateFormat: PropTypes.string,
};

OrderDetails.defaultProps = {
  className: 'OrderDetails',
  dateFormat: 'L',
};

export default OrderDetails;
