import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import fetch from 'fetch-hoc';
import template from 'url-template';
import { Link } from '@oms/components-typography';
import { compose, defaultProps } from 'recompose';
import {
  formatLink,
  formatNumber,
  formatInteger,
  formatDate,
} from '@oms/utils';
import { I18n } from '@lingui/react';
import { Trans, t } from '@lingui/macro';
import { SizeMe } from 'react-sizeme';
import { Row } from '@oms/components';

import { mapType, orderByDate } from './utils';
import * as styles from './styles';
import namespaceFetch from './namespaceFetch';
import normalize from './normalize';

/* eslint-disable react/no-unused-prop-types */

/**
 * **This component is part of the trading components package and requires the corresponding license**
 *
 * Fetches and displays a table of the provided user's transaction history. The
 * dataset is filterable based on a provided `filters` object.
 *
 * Fetches the data from url specified in ComponentContext.
 * @see See [Context](/#!/Context)
 *
 * @see Is a part of the [TransactionsArchive](/#!/TransactionsArchive) component.
 * @since 1.0.0
 */
export class TransactionsTable extends React.Component {
  state = {
    expanded: null,
  };

  handleRowClick = key => {
    this.setState(({ expanded: prevExpanded }) => ({
      expanded: prevExpanded === key ? null : key,
    }));
  };

  getVisibleCols = size => {
    const { width } = size;

    if (width < 330) return 2;

    if (width < 480) return 3;

    if (width < 570) return 4;

    if (width < 768) return 5;

    if (width < 900) return 6;

    return null;
  };

  render() {
    const {
      type,
      className,
      accountId,
      linkTo,
      orderLink,
      transactionLink,
      dateFormat,
      fundTransactions = {},
      stockTransactions = {},
    } = this.props;

    const { expanded } = this.state;

    const success = fundTransactions.success || stockTransactions.success;

    const getTransactions = () => {
      if (fundTransactions.data && stockTransactions.data) {
        return fundTransactions.data.concat(stockTransactions.data);
      }

      if (fundTransactions.data) return fundTransactions.data;
      if (stockTransactions.data) return stockTransactions.data;

      return [];
    };

    const transactions = getTransactions();
    const showPrice = type === 'stock';
    return (
      <I18n>
        {({ i18n }) => {
          const trans = key => (i18n ? i18n._(key) : key.id);
          return (
            <SizeMe>
              {({ size }) => (
                <table
                  className={className}
                  css={styles.transactionsTable}
                  data-testid="TransactionsTable"
                >
                  <thead>
                    <Row visibleColumns={this.getVisibleCols(size)}>
                      <th className="ticker text" weight={1.3}>
                        <Trans>Company</Trans>
                      </th>
                      <th className="type text" weight={0.6}>
                        <Trans>Type</Trans>
                      </th>
                      <th className="totalprice number" weight={1.2}>
                        <Trans>Total price</Trans>
                      </th>
                      <th className="volume number">
                        <Trans>Volume</Trans>
                      </th>
                      {showPrice && (
                        <th className="price number">
                          <Trans>Price</Trans>
                        </th>
                      )}
                      <th className="executed date" weight={1.1}>
                        <Trans>Executed</Trans>
                      </th>
                      <th className="transaction-id" weight={1.3}>
                        <Trans>Transaction</Trans>
                      </th>
                      <th className="order-id" weight={1.2}>
                        <Trans>Order no.</Trans>
                      </th>
                    </Row>
                  </thead>
                  <tbody>
                    {success &&
                      transactions.sort(orderByDate).map((item, index) => {
                        const {
                          tickerCode,
                          stockExchangeId,
                          transactionId,
                          orderId,
                          tradeType,
                          volume,
                          price,
                          executedDate,
                          transactionFee,
                          instrumentType,
                          instrumentName,
                          isin,
                          priceLocal,
                          transactionCode,
                        } = item;
                        const isFund = instrumentType === 'fund';
                        const pricePerShare = isFund ? priceLocal : price;

                        let totalPrice;
                        if (tradeType === 'BUY') {
                          totalPrice = volume * pricePerShare + transactionFee;
                        } else if (tradeType === 'SELL') {
                          totalPrice = volume * pricePerShare - transactionFee;
                        }

                        const ITEM_SECTOR = isFund
                          ? `${isin}.OMFF`
                          : `${tickerCode}.${stockExchangeId}`;

                        const name = isFund
                          ? instrumentName || isin
                          : instrumentName || tickerCode;

                        const variables = {
                          ...item,
                          ITEM_SECTOR,
                          type: instrumentType,
                          accountId,
                        };

                        // Not sure why, but some transactions do not have an ID.
                        // Safe fallback to index as key
                        const key =
                          orderId && transactionId
                            ? `${orderId}.${transactionId}`
                            : index;

                        const visibleColumns = this.getVisibleCols(size);

                        return (
                          <Row
                            className="transactionRow"
                            key={key}
                            onExpand={
                              visibleColumns !== null
                                ? () => this.handleRowClick(key)
                                : undefined
                            }
                            expanded={expanded === key}
                            visibleColumns={visibleColumns}
                          >
                            <td
                              className="ticker text"
                              data-header={trans(t`Company`)}
                              weight={1.3}
                            >
                              {(isFund && isin) ||
                              (tickerCode && stockExchangeId) ? (
                                <Link to={formatLink(linkTo, variables)}>
                                  {name}
                                </Link>
                              ) : (
                                name
                              )}
                            </td>
                            <td
                              className="type text"
                              weight={0.6}
                              data-header={trans(t`Type`)}
                            >
                              {mapType(tradeType, transactionCode)}
                            </td>
                            <td
                              className="totalprice number"
                              data-header={trans(t`Total price`)}
                              weight={1.2}
                            >
                              {formatNumber(totalPrice)}
                            </td>
                            <td
                              className="volume number"
                              data-header={trans(t`Volume`)}
                            >
                              {isFund
                                ? formatNumber(volume, 4)
                                : formatInteger(volume)}
                            </td>
                            {showPrice && (
                              <td
                                className="price number"
                                data-header={trans(t`Price`)}
                              >
                                {formatNumber(pricePerShare)}
                              </td>
                            )}
                            <td
                              className="executed date"
                              data-header={trans(t`Executed`)}
                              weight={1.1}
                            >
                              <time>
                                {formatDate(
                                  executedDate,
                                  ['HH:mm', dateFormat],
                                  moment.locale(),
                                )}
                              </time>
                            </td>
                            <td
                              className="transaction-id"
                              data-header={trans(t`Transaction`)}
                              weight={1.3}
                            >
                              <Link to={formatLink(transactionLink, variables)}>
                                {transactionId}
                              </Link>
                            </td>
                            <td
                              className="order-id"
                              data-header={trans(t`Order no.`)}
                              weight={1.2}
                            >
                              <Link to={formatLink(orderLink, variables)}>
                                {orderId}
                              </Link>
                            </td>
                          </Row>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </SizeMe>
          );
        }}
      </I18n>
    );
  }
}

TransactionsTable.defaultProps = {
  dateFormat: 'L',
  linkTo: '/instrument/{ITEM_SECTOR}',
  orderLink: '/order/{orderId}{?type}',
  transactionLink: '/order/{orderId}/transaction/{transactionId}{?type}',
  className: 'TransactionsTable',
  type: 'fund-and-stock',
};

TransactionsTable.propTypes = {
  /** The currently logged in user */
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** The currently logged in user's selected account */
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /**
   * A momentjs format string that the date picker will use. Default of `L` is
   * a localized short format based on the configured momentjs locale.
   */
  dateFormat: PropTypes.string,
  /**
   * The links on the instruments.
   *
   * The format of the links: Two formats are recognized. In both cases,
   * any strings are interpereted as an RFC6570 compatible template and
   * interpolated with the columns on the rows of the component.
   *
   * The two accepted inputs are the same as the react-router
   * `to`-prop. Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/location.md).
   */
  linkTo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      key: PropTypes.string,
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object,
    }),
  ]),

  /**
   * The links that lead to an order details page.
   *
   * The format of the links: Two formats are recognized. In both cases,
   * any strings are interpereted as an RFC6570 compatible template and
   * interpolated with the columns on the rows of the component.
   *
   * The two accepted inputs are the same as the react-router
   * `to`-prop. Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/location.md).
   */
  orderLink: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      key: PropTypes.string,
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object,
    }),
  ]),
  /**
   * The links that lead to a transaction details page.
   *
   * The format of the links: Two formats are recognized. In both cases,
   * any strings are interpereted as an RFC6570 compatible template and
   * interpolated with the columns on the rows of the component.
   *
   * The two accepted inputs are the same as the react-router
   * `to`-prop. Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/location.md).
   */
  transactionLink: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      key: PropTypes.string,
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object,
    }),
  ]),
  /** The filters used to filter the table dataset */
  filters: PropTypes.shape({
    ticker: PropTypes.shape({}),
    orderId: PropTypes.string,
    transactionId: PropTypes.string,
    fromDate: PropTypes.string.isRequired,
    toDate: PropTypes.string.isRequired,
  }).isRequired,
  /** Will be passed to the component */
  className: PropTypes.string,
  /** The entrypoint to the api. */
  baseUrl: PropTypes.string.isRequired,
  /** The endpoint used to search for transactions. String is interpereted as an RFC6570 compatible template */
  transactionSearchUrl: PropTypes.string.isRequired,
  /**
   * Specifies what kind of transaction to search for
   */
  type: PropTypes.oneOf(['fund', 'stock', 'fund-and-stock']),

  /** @ignore */
  stockTransactions: PropTypes.shape({
    data: PropTypes.array,
    success: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.object,
  }),
  /** @ignore */
  fundTransactions: PropTypes.shape({
    data: PropTypes.array,
    success: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.object,
  }),
};

const serializationFormat = 'YYYY-MM-DD';

export default compose(
  defaultProps(TransactionsTable.defaultProps),
  fetch(
    ({
      filters,
      dateFormat,
      userId,
      accountId,
      type,
      baseUrl,
      transactionSearchUrl,
    }) => {
      const urlTemplate = template.parse(transactionSearchUrl);
      if (type !== 'stock' && type !== 'fund-and-stock') return null;
      return urlTemplate.expand({
        baseUrl,
        userId,
        accountId,
        type: 'stock',
        filters: {
          ...filters,
          ticker: filters.ticker ? filters.ticker.ITEM : undefined,
          fromDate: moment(filters.fromDate, dateFormat).format(
            serializationFormat,
          ),
          toDate: moment(filters.toDate, dateFormat).format(
            serializationFormat,
          ),
        },
      });
    },
  ),
  normalize(
    data =>
      data &&
      data.map &&
      data.map(transaction => ({ ...transaction, instrumentType: 'stock' })),
  ),
  namespaceFetch('stockTransactions'),

  fetch(
    ({
      filters,
      dateFormat,
      userId,
      accountId,
      type,
      baseUrl,
      transactionSearchUrl,
    }) => {
      const urlTemplate = template.parse(transactionSearchUrl);
      if (type !== 'fund' && type !== 'fund-and-stock') return null;
      return urlTemplate.expand({
        baseUrl,
        userId,
        accountId,
        type: 'fund',
        filters: {
          ...filters,
          isin: filters.ticker ? filters.ticker.ISIN : undefined,
          sector: filters.ticker ? filters.ticker.SECTOR : undefined,
          currency: filters.ticker ? filters.ticker.CURRENCY : undefined,
          fromDate: moment(filters.fromDate, dateFormat).format(
            serializationFormat,
          ),
          toDate: moment(filters.toDate, dateFormat).format(
            serializationFormat,
          ),
        },
      });
    },
  ),
  normalize(
    data =>
      data &&
      data.map &&
      data.map(transaction => ({ ...transaction, instrumentType: 'fund' })),
  ),
  namespaceFetch('fundTransactions'),
)(TransactionsTable);
