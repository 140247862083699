import React from 'react';
import PropTypes from 'prop-types';
import { Consumer as ComponentsConsumer } from '@oms/components-context';

/**
 * Used internally in the OMS components to fetch a link component from context
 */
export const LinkWrapper = ({ to, children, ...props }) => (
  <ComponentsConsumer>
    {({ Link }) => (
      <Link {...props} to={to}>
        {children}
      </Link>
    )}
  </ComponentsConsumer>
);

LinkWrapper.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      query: PropTypes.object.isRequired,
    }),
  ]),
  children: PropTypes.node,
};

export default LinkWrapper;
