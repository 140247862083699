import React from 'react';
import PropTypes from 'prop-types';
import { Consumer as ComponentsContext } from '@oms/components-context';
import { compose, defaultProps, withState } from 'recompose';

import TransactionsFilters, {
  buildDefaultFilters,
} from './TransactionsFilters';
import TransactionsTable from './TransactionsTable';

/**
 * **This component is part of the trading components package and requires the corresponding license**
 *
 * A component which lists the currently logged in user's previous transactions.
 * Includes filters for searching and filtering the result set.
 * Both fund- and stock-transactions are searched unless specified by prop.
 *
 * Unknown props are spread into `TransactionsTable`.
 *
 * @see See [TransactionsFilters](/#!/TransactionsFilters) for the filter component.
 * @see See [TransactionsTable](/#!/TransactionsTable) for the table component.
 * @since 1.0.0
 */
export const TransactionsArchive = props => {
  const {
    className,
    filters,
    userId,
    updateFilters,
    filterProps,
    ...rest
  } = props;

  return (
    <ComponentsContext>
      {({ baseUrl, transactionSearchUrl }) => (
        <section className={className}>
          <TransactionsFilters
            onFiltersChange={updateFilters}
            {...filterProps}
          />
          <TransactionsTable
            filters={filters}
            userId={userId}
            baseUrl={baseUrl}
            transactionSearchUrl={transactionSearchUrl}
            {...rest}
          />
        </section>
      )}
    </ComponentsContext>
  );
};

TransactionsArchive.defaultProps = {
  dateFormat: 'L',
  className: 'TransactionsArchive',
  type: 'fund-and-stock',
};

TransactionsArchive.propTypes = {
  /** The currently logged in user */
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** The currently logged in user's selected account */
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /**
   * Props that are passed to the `TransactionsFilters` component.
   *
   * @see See `TransactionsFilters` for available props
   */
  filterProps: PropTypes.shape({}),
  /**
   * A momentjs format string that the date picker will use. Default of `L` is
   * a localized short format based on the configured momentjs locale.
   */
  dateFormat: PropTypes.string,
  /** Will be passed to the component */
  className: PropTypes.string,
  /**
   * Specifies what kind of transactions to search for.
   */
  type: PropTypes.oneOf(['fund', 'stock', 'fund-and-stock']),

  /** @ignore */
  filters: PropTypes.shape({}),
  /** @ignore */
  updateFilters: PropTypes.func.isRequired,
};

export default compose(
  defaultProps(TransactionsArchive.defaultProps),
  withState('filters', 'updateFilters', buildDefaultFilters),
)(TransactionsArchive);
