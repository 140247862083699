import { css } from '@emotion/core';
import React from 'react';
import PropTypes from 'prop-types';
import { normalizeNumber } from '@oms/utils';

import NumberInput from './NumberInput';
import * as styles from './styles';

const visuallyHidden = css(styles.visuallyHidden);

const calculateWidth = (value = 10, fontSize = '16px') =>
  Math.max(
    20,
    Math.ceil(value.toString().length * (fontSize.replace('px', '') / 1.6)),
  );

/**
 * A more specialized version of the [NumberInput](/#!/NumberInput) component.
 * This component handles decimal separators in a more-or-less cross-browser
 * consistent way, that is to say commas and periods are treated equally.
 *
 * Implementation-wise it is a component consisting of two inputs which are
 * styled to look somewhat like a single input. Typing comma in the inegral
 * input will move focus to the fractional input.
 *
 * When the element is resized, only the number part is resized. It is highly
 * recommended to have the component fill 100% width of a containing element, as
 * the input may behave strangely otherwise.
 *
 * As you type fractional digits, the fractional section will resize into the
 * integral section.
 *
 * You may encounter that Chrome on Android does not allow entering commas
 * unless the OS is set to Norwegian or European English. This is implementation
 * specific behavior on Android.
 *
 * To prevent the blurred input from being formatted as a telephone number in
 * on iOS devices, the component sets a meta tag in the `<head>` of the document
 * that supresses formatting telephonen numbers as links. Note that you can
 * still use `tel:` href links if clickable links are desired elsewhere.
 *
 * For now, controlled DecimalInput components require the new lifecycle methods
 * introduced in React 16.3. This may be polyfilled if this is requested enough,
 * but for now it is not.
 *
 * @since 1.0.0
 */
export class DecimalInput extends React.Component {
  static propTypes = {
    /** A className which will be forwarded to the wrapping fieldset element */
    className: PropTypes.string,
    /**
     * A label that is shown above the input field. It should describe what the
     * number is
     */
    label: PropTypes.string.isRequired,
    /**
     * A label to associate with the integer input. It is visually hidden and
     * not visible to a sighted user
     */
    integerLabel: PropTypes.string.isRequired,
    /**
     * If provided, will render an addon with the given text on the left hand
     * side of the input
     */
    leftAddonText: PropTypes.string,
    /**
     * If provided, will render an addon with the given text on the left hand
     * side of the input
     */
    rightAddonText: PropTypes.string,
    /**
     * A label to associate with the fraction input. It is visually hidden and
     * not visible to a sighted user
     */
    fractionLabel: PropTypes.string.isRequired,
    /** The initial value of the component */
    initialValue: PropTypes.number,
    /**
     * Is called whenever the input changes in either input. Will always return
     * a javascript number.
     */
    onChange: PropTypes.func,
    /** See the [NumberInput](/#!/NumberInput) component for available props */
    integerInputProps: PropTypes.shape({}),
    /** See the [NumberInput](/#!/NumberInput) component for available props */
    fractionInputProps: PropTypes.shape({}),
  };

  static defaultProps = {
    className: 'DecimalInput',
  };

  constructor(props) {
    super(props);
    const { initialValue } = this.props;
    this.state = {};

    if (initialValue) {
      const [integer, fraction = '0'] = initialValue.toString().split('.');
      this.state = { integer, fraction };
    }
  }

  componentDidMount() {
    // iOS devices format the number as a telephone number - we attach a meta
    // tag to the <head> to suppress that behavior
    const exists = document.querySelector('meta[name=format-detection]');
    if (!exists) {
      const element = document.createElement('meta');
      element.setAttribute('name', 'format-detection');
      element.setAttribute('content', 'telephone=no');
      document.querySelector('head').appendChild(element);
    }
  }

  static getDerivedStateFromProps({ value }) {
    if (value !== undefined) {
      const [integer, fraction] = value.toString().split('.');
      return { integer, fraction };
    }

    return null;
  }

  getFractionFontSize = () => {
    if (this.decimalRef) {
      return getComputedStyle(this.decimalRef.inputRef).getPropertyValue(
        'font-size',
      );
    }

    return undefined;
  };

  handleIntegerInput = event => {
    if (event.data === ',' || event.data === '.') {
      // Prevent key press
      event.preventDefault();
      this.decimalRef.inputRef.focus();
      this.decimalRef.inputRef.select();
    }
  };

  handleIntegerKeyDown = event => {
    if (event.key === ',' || event.key === '.') {
      // Prevent key press
      event.preventDefault();
      this.decimalRef.inputRef.focus();
      this.decimalRef.inputRef.select();
    }
  };

  handleFractionInput = event => {
    if (event.data === ',' || event.data === '.') {
      // Prevent key press
      event.preventDefault();
    }
  };

  handleFractionKeyDown = event => {
    const { fraction } = this.state;

    if (event.key === ',' || event.key === '.') {
      // Prevent key press
      event.preventDefault();
    }

    if (event.key === 'Backspace' && !fraction) {
      // When pressing backspace in an empty fraction, the backspace event is
      // moved into the integral input and an integral digit is removed. This
      // confuses people who expect to delete the comma. Thus we prevent the
      // event which gives the impression of deleting the comma.
      event.preventDefault();

      // Move focus over to the integral input
      this.integerRef.inputRef.focus();
    }
  };

  handleIntegerChange = event => {
    const integer = event.target.value;
    const { fraction } = this.state;

    // dispatchNewValue needs to be called outside of setState callback to work
    // with getDerivedStateFromProps
    this.dispatchNewValue(integer, fraction);
    this.setState({ integer });
  };

  handleFractionFocus = event => {
    const { fractionInputProps } = this.props;
    this.setState({ fractionIsFocused: true });

    if (fractionInputProps && fractionInputProps.onFocus) {
      fractionInputProps.onFocus(event);
    }
  };

  handleFractionBlur = event => {
    const { fractionInputProps } = this.props;
    this.setState({ fractionIsFocused: false });

    if (fractionInputProps && fractionInputProps.onBlur) {
      fractionInputProps.onBlur(event);
    }
  };

  handleFractionChange = event => {
    const fraction = event.target.value;
    const { integer } = this.state;

    // dispatchNewValue needs to be called outside of setState callback to work
    // with getDerivedStateFromProps
    this.dispatchNewValue(integer, fraction);
    this.setState({ fraction: event.target.value });
  };

  dispatchNewValue = (integer, fraction) => {
    const { onChange } = this.props;

    if (onChange) onChange(normalizeNumber(`${integer}.${fraction}`));
  };

  assignIntegerRef = ref => {
    this.integerRef = ref;
  };

  assignDecimalRef = ref => {
    this.decimalRef = ref;
  };

  render() {
    const { integer, fraction, fractionIsFocused } = this.state;
    const {
      className,
      label,
      integerLabel,
      fractionLabel,
      integerInputProps,
      fractionInputProps,
      leftAddonText,
      rightAddonText,
    } = this.props;

    const fractionIsEmpty = !fraction || fraction === '0';
    const hideFraction = fractionIsEmpty && !fractionIsFocused;

    /* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */

    return (
      <fieldset className={className} css={styles.DecimalInput}>
        <legend>{label}</legend>
        <span css={styles.unifiedInput}>
          {leftAddonText && <div css={styles.leftAddon}>{leftAddonText}</div>}

          <label css={styles.integerLabel}>
            <span css={styles.visuallyHidden}>{integerLabel}</span>
            <NumberInput
              {...integerInputProps}
              value={integer}
              css={styles.integerInput}
              ref={this.assignIntegerRef}
              onChange={this.handleIntegerChange}
              onKeyDown={this.handleIntegerKeyDown}
              onBeforeInput={this.handleIntegerInput}
              data-testid="integerInput"
            />
          </label>

          <div
            aria-hidden="true"
            css={[styles.decimalSeparator, visuallyHidden && hideFraction]}
          >
            ,
          </div>

          <label css={[visuallyHidden && hideFraction]}>
            <span css={styles.visuallyHidden}>{fractionLabel}</span>
            <NumberInput
              {...fractionInputProps}
              disableFormatting
              value={fraction}
              css={css`
                & input,
                & input + div {
                  width: ${calculateWidth(
                    fraction,
                    this.getFractionFontSize(),
                  )}px;
                }
              `}
              ref={this.assignDecimalRef}
              onFocus={this.handleFractionFocus}
              onBlur={this.handleFractionBlur}
              onChange={this.handleFractionChange}
              onKeyDown={this.handleFractionKeyDown}
              onBeforeInput={this.handleFractionInput}
              data-testid="fractionInput"
            />
          </label>

          {rightAddonText && (
            <div css={styles.rightAddon}>{rightAddonText}</div>
          )}
        </span>
      </fieldset>
    );
  }
}

export default DecimalInput;
