import React from 'react';
import PropTypes from 'prop-types';
import { Suggest } from '@oms/components';
import DefaultErrorBoundary from './DefaultErrorBoundary';

const host = `//${process.env.JAWS_URL || window.location.host}`;
const baseUrl = `${host}/server`;

const DefaultLink = React.forwardRef(({ to, children, ...props }, ref) => (
  <a href={to} ref={ref} {...props}>
    {children}
  </a>
));

DefaultLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
};

export default {
  baseUrl,
  domainUrl: '{+baseUrl}/domain/{?query*}',
  graphdataUrl:
    '{+baseUrl}/components/graphdata/{series}/{space}/{itemSector}{?query*}',
  transactionsUrl:
    '{+baseUrl}/secure/transactions/:backend/:userId/:portfolioId(/:instrumentId(/:transactionId)){?query*}',
  orderUrl:
    '{+baseUrl}/secure/customers/{userId}/{type}-orders/{orderId}{?accountId}',
  orderContractNotesUrl:
    '{+baseUrl}/secure/customers/{userId}/{type}-orders/{orderId}/contract-notes{?accountId}',
  orderTransactionUrl:
    '{+baseUrl}/secure/customers/{userId}/{type}-orders/{orderId}/transactions/{transactionId}{?accountId}',
  fundTransactionUrl:
    '{+baseUrl}/secure/customers/{userId}/fund-transactions/{transactionId}{?accountId}',
  orderSearchUrl:
    '{+baseUrl}/secure/customers/{userId}/{type}-orders/search{?accountId,filters*}',
  transactionSearchUrl:
    '{+baseUrl}/secure/customers/{userId}/{type}-transactions/search{?accountId,filters*}',
  jawsUrl: '{+baseUrl}/components{?spec*}',
  Link: DefaultLink,
  ErrorBoundary: DefaultErrorBoundary,
  Suggest,
};
