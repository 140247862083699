import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { normalizeNumber } from '@oms/utils';

import * as styles from './styles';

const formatter = new Intl.NumberFormat('nb', {
  maximumFractionDigits: 20,
});
const format = number => formatter.format(number);

const isNumeric = key => /^([0-9]+[.,]?|[0-9,.]+)[0-9]*$/.test(key);
const controlKeys = [
  'ArrowLeft',
  'ArrowUp',
  'ArrowRight',
  'ArrowDown',
  'Backspace',
  'Delete',
  'Tab',
];
const isControlKey = key => controlKeys.includes(key);
// Meta is command on mac, windows on windows
const superPressed = event => event.ctrlKey || event.metaKey;

const shouldPreventKeyPress = event => {
  const { key } = event;

  // User is trying to run a browser level command
  if (superPressed(event)) return false;

  // User is trying to navigate inside the input
  if (isControlKey(key)) return false;

  // User is entering valid input
  if (isNumeric(key)) return false;

  return true;
};

// Prevent pasting anything containing non-numeric data, as this resets the
// input
const handlePaste = event => {
  const pastedData = (event.clipboardData || window.clipboardData).getData(
    'text',
  );

  if (!isNumeric(pastedData)) {
    // TODO We could show a message about blocking paste here.. Should we?
    event.preventDefault();
  }
};

/**
 * A number input component that shows the number formatted in the norwegian
 * locale when the input is blurred. When the input is focused, the number
 * contracts to make editing easier.
 *
 * Note that as this uses the native number input in order to get the numeric
 * soft keyboard on mobile, the behaviour of the input is implementation
 * defined. Some browsers allow commas as decimal separators, some do not. See
 * [this blog post](https://www.ctrl.blog/entry/html5-input-number-localization)
 * for more information. For cross-browser consistent decimal support use the
 * [DecimalNumber](/#!/DecimalNumber) component instead.
 *
 * This input is tested to be accessible and shows the number keyboard on mobile
 * devices.
 *
 * @since 1.0.0
 */
class NumberInput extends Component {
  static propTypes = {
    /** A className which will be forwarded to the wrapping div element */
    className: PropTypes.string,
    /**
     * The language used for the number input. Setting this to nb ensures that
     * most browsers allow commas as decimal separators in addition to periods
     */
    lang: PropTypes.string,
    /** Initial value for uncontrolled components */
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Disables formatting of the number when set to true */
    disableFormatting: PropTypes.bool,
    /** Called whenever the value changes */
    onChange: PropTypes.func,
    /**
     * Called whenever the user has clicked a button. If an input event contains
     * a key which is invalid, then the input event is supressed. This is to
     * prevent the user entering invalid input like characters into the number
     * field.
     *
     * `onKeyDown` is called in either case.
     */
    onKeyDown: PropTypes.func,
    /**
     * A placeholder to be shown when the input is empty. Implementation detail:
     * the psuedo input that is shown when the input is blurred also contains
     * the placeholder for a consistent experience.
     */
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    className: 'NumberInput',
    lang: 'nb',
    disableFormatting: false,
    defaultValue: '',
  };

  state = {
    // eslint-disable-next-line react/destructuring-assignment
    value: this.props.defaultValue,
  };

  static getDerivedStateFromProps({ value }) {
    if (value !== undefined) {
      return { value };
    }
    return null;
  }

  // onChange event contains value = '' if anything non-numeric is entered
  handleChange = event => {
    const { onChange } = this.props;
    const { value } = event.target;

    this.setState({ value: normalizeNumber(value) });
    if (onChange) onChange(event);
  };

  handleKeyDown = event => {
    const { onKeyDown } = this.props;

    if (shouldPreventKeyPress(event)) {
      event.preventDefault();
    }

    if (onKeyDown) onKeyDown(event);
  };

  handlePlaceholderClick = () => {
    this.inputRef.focus();
  };

  assignRef = ref => {
    this.inputRef = ref;
  };

  render() {
    const { value } = this.state;
    const {
      className,
      defaultValue,
      placeholder,
      lang,
      disableFormatting,
      ...rest
    } = this.props;
    const formattedValue = disableFormatting ? value : format(value);

    return (
      <div css={styles.NumberInput} className={className}>
        <input
          data-testid="input"
          {...rest}
          lang={lang}
          placeholder={placeholder}
          type="number"
          autoComplete="off"
          ref={this.assignRef}
          value={value}
          onKeyDown={this.handleKeyDown}
          onPaste={handlePaste}
          onChange={this.handleChange}
        />
        <styles.PsuedoInput
          aria-hidden="true"
          isEmpty={value === ''}
          onClick={this.handlePlaceholderClick}
        >
          {value === '' ? placeholder : formattedValue}
        </styles.PsuedoInput>
      </div>
    );
  }
}

export default NumberInput;
