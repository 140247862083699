import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const NumberInput = css`
  /* Make both input and placeholder look like an input */
  & input,
  & input + div {
    display: block;
    cursor: text;
    height: 21px;
    width: 160px;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 16px;
  }

  /* Remove controls from Firefox */
  & input {
    -moz-appearance: textfield;
  }

  /* Remove controls from Safari and Chrome */
  & input::-webkit-inner-spin-button,
  & input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; /* Removes leftover margin */
  }

  & input::-ms-clear {
    display: none;
  }

  /* Visually hide input, still visible to screen readers */
  /* Placeholder element should not be visible when input is */
  & input:not(:focus),
  & input:focus + div {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
`;

export const PsuedoInput = styled.div`
  color: ${({ isEmpty }) => isEmpty && '#767676'};
`;

export const DecimalInput = css`
  display: block;
  border: 0;
`;

export const unifiedInput = css`
  display: flex;
  min-width: 250px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);

  & label input,
  & label input + div {
    border: 0;
  }

  & label {
    display: inline-block;
  }
`;

export const integerLabel = css`
  flex: 1;
`;

export const integerInput = css`
  & input,
  & input + div {
    height: 100%;
    width: 100%;
    text-align: right;
    box-sizing: border-box;
  }
`;

export const decimalSeparator = css`
  display: table-cell;
  vertical-align: bottom;
  align-self: center;

  /* Lower margin between integer and fraction while keeping separator on top */
  margin-left: -3px;
  margin-right: -3px;
  z-index: 200;
`;

export const leftAddon = css`
  align-self: center;
  padding: 0 5px;
`;

export const rightAddon = css`
  align-self: center;
  padding-right: 5px;
`;

export const visuallyHidden = css`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`;
