import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import fetchHoc from 'fetch-hoc';
import template from 'url-template';
import { Link } from '@oms/components-typography';
import { compose, defaultProps } from 'recompose';
import { formatLink, formatNumber, formatInteger } from '@oms/utils';
import { I18n } from '@lingui/react';
import { Trans, t } from '@lingui/macro';
import { SizeMe } from 'react-sizeme';
import { Row } from '@oms/components';

import { mapType, mapStatus, mapStatusIcon, orderByDate } from './utils';
import * as styles from './styles';
import namespaceFetch from './namespaceFetch';
import normalize from './normalize';

/* eslint-disable react/no-unused-prop-types */

/**
 * **This component is part of the trading components package and requires the corresponding license**
 *
 * Fetches and displays a table of the provided user's order history. The
 * dataset is filterable based on a provided `filters` object.
 *
 * Fetches the data from url specified in ComponentContext.
 * @see See [Context](/#!/Context)
 *
 * @see Is a part of the [OrderArchive](/#!/OrderArchive) component.
 * @since 1.0.0
 */
export class OrderTable extends React.Component {
  state = {
    expanded: null,
    longNames: {},
  };

  componentDidMount() {
    this.fetchFundData();
  }

  componentDidUpdate(prevProps) {
    const { fundOrders } = this.props;
    if (!prevProps.fundOrders.success && fundOrders.success) {
      this.fetchFundData();
    }
  }

  fetchFundData = () => {
    const { fundOrders } = this.props;
    if (!fundOrders || !fundOrders.success) return;

    const fetched = {};
    fundOrders.data.forEach(async ({ isin }) => {
      if (!isin || fetched[isin]) return;

      fetched[isin] = true;
      const longName = await this.fetchFundName(isin);
      this.setState(({ longNames }) => ({
        longNames: { ...longNames, [isin]: longName },
      }));
    });
  };

  fetchFundName = async isin => {
    const { baseUrl } = this.props;
    const response = await fetch(`${baseUrl}/domain/?item=${isin}`);

    if (!response.ok) return '';

    try {
      const result = await response.json();
      return result[0].LONG_NAME;
    } catch (e) {
      return '';
    }
  };

  handleRowClick = key => {
    this.setState(({ expanded: prevExpanded }) => ({
      expanded: prevExpanded === key ? null : key,
    }));
  };

  getVisibleCols = size => {
    const { width } = size;

    if (width < 420) return 2;

    if (width < 480) return 3;

    if (width < 570) return 4;

    if (width < 768) return 5;

    if (width < 900) return 6;

    return 7;
  };

  render() {
    const {
      accountId,
      className,
      getStatusIconClass,
      linkTo,
      orderLink,
      dateFormat,
      fundOrders,
      stockOrders,
    } = this.props;

    const { expanded, longNames } = this.state;

    const success = fundOrders.success || stockOrders.success;

    const getOrders = () => {
      if (fundOrders.data && stockOrders.data) {
        return fundOrders.data.concat(stockOrders.data);
      }

      if (fundOrders.data) return fundOrders.data;
      if (stockOrders.data) return stockOrders.data;

      return [];
    };

    const orders = getOrders();

    return (
      <I18n>
        {({ i18n }) => {
          const trans = key => (i18n ? i18n._(key) : key.id);
          return (
            <SizeMe>
              {({ size }) => (
                <table
                  className={className}
                  css={styles.orderTable}
                  data-testid="OrderTable"
                >
                  <thead>
                    <Row visibleColumns={this.getVisibleCols(size)}>
                      <th className="ticker text" weight={1.2}>
                        <Trans>Company</Trans>
                      </th>
                      <th className="volume number" weight={0.8}>
                        <Trans>Volume</Trans>
                      </th>
                      <th className="status text" weight={1.1}>
                        <Trans>Status</Trans>
                      </th>

                      <th className="validity date" weight={1.3}>
                        <Trans>Validity</Trans>
                      </th>
                      <th className="type text" weight={0.6}>
                        <Trans>Type</Trans>
                      </th>

                      <th className="rest number">
                        <Trans>Rest</Trans>
                      </th>
                      <th className="limit number">
                        <Trans>Limit</Trans>
                      </th>
                      <th className="stoploss number">
                        <Trans>Stop loss</Trans>
                      </th>
                      <th className="visible number">
                        <Trans>Visible volume</Trans>
                      </th>
                      <th className="order-id text" weight={1.2}>
                        <Trans>Order no.</Trans>
                      </th>
                    </Row>
                  </thead>
                  <tbody>
                    {success &&
                      orders.sort(orderByDate).map((item, index) => {
                        const {
                          stopLossLimit,
                          totalVolume,
                          limit,
                          tickerCode,
                          instrumentName,
                          hiddenVolume,
                          expirationDate,
                          orderId,
                          stockExchangeId,
                          remainingVolume,
                          orderStatus,
                          tradeType,
                          instrumentType,
                          isin,
                        } = item;

                        const isFund = instrumentType === 'fund';

                        const itemSector = isFund
                          ? `${isin}.${stockExchangeId}`
                          : `${tickerCode}.${stockExchangeId}`;

                        const name = isFund
                          ? longNames[isin] || isin
                          : instrumentName || tickerCode;

                        const variables = {
                          ...item,
                          ORDER_ID: orderId,
                          ITEM_SECTOR: itemSector,
                          type: instrumentType,
                          accountId,
                        };

                        const visibleCols = this.getVisibleCols(size);
                        const key = `${orderId}-${index}`;

                        return (
                          <Row
                            className="orderRow"
                            key={key}
                            onExpand={
                              visibleCols !== null
                                ? () => this.handleRowClick(key)
                                : undefined
                            }
                            expanded={expanded === key}
                            visibleColumns={visibleCols}
                          >
                            <td
                              className="ticker text"
                              data-header={trans(t`Company`)}
                              weight={1.2}
                            >
                              {stockExchangeId ? (
                                <Link to={formatLink(linkTo, variables)}>
                                  {name}
                                </Link>
                              ) : (
                                name
                              )}
                            </td>
                            <td
                              className="volume number"
                              data-header={trans(t`Volume`)}
                              weight={0.8}
                            >
                              {isFund
                                ? formatNumber(totalVolume, 4)
                                : formatInteger(totalVolume)}
                            </td>
                            <td
                              className="status text"
                              data-header={trans(t`Status`)}
                              weight={1.1}
                            >
                              {mapStatus(orderStatus, isFund)}
                              <i className={getStatusIconClass(orderStatus)} />
                            </td>

                            <td
                              className="validity date"
                              data-header={trans(t`Validity`)}
                              weight={1.3}
                            >
                              <time>
                                {moment(expirationDate).format(dateFormat)}
                              </time>
                            </td>
                            <td
                              className="type text"
                              data-header={trans(t`Type`)}
                              weight={0.6}
                            >
                              {mapType(tradeType)}
                            </td>
                            <td
                              className="rest number"
                              data-header={trans(t`Rest`)}
                            >
                              {formatInteger(remainingVolume)}
                            </td>
                            <td
                              className="limit number"
                              data-header={trans(t`Limit`)}
                            >
                              {formatNumber(limit)}
                            </td>
                            <td
                              className="stoploss number"
                              data-header={trans(t`Stop loss`)}
                            >
                              {formatNumber(stopLossLimit)}
                            </td>
                            <td
                              className="visible number"
                              data-header={trans(t`Visible volume`)}
                            >
                              {isFund
                                ? '-'
                                : formatInteger(totalVolume - hiddenVolume)}
                            </td>
                            <td
                              className="order-id text"
                              data-header={trans(t`Order no.`)}
                              weight={1.2}
                            >
                              <Link to={formatLink(orderLink, variables)}>
                                {orderId}
                              </Link>
                            </td>
                          </Row>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </SizeMe>
          );
        }}
      </I18n>
    );
  }
}

OrderTable.defaultProps = {
  dateFormat: 'L',
  linkTo: '/instrument/{ITEM_SECTOR}{?type}',
  orderLink: '/order/{ORDER_ID}{?type}',
  getStatusIconClass: mapStatusIcon,
  className: 'OrderTable',
  type: 'fund-and-stock',
};

OrderTable.propTypes = {
  /** The currently logged in user */
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** The currently logged in user's selected account */
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /**
   * A momentjs format string that the date picker will use. Default of `L` is
   * a localized short format based on the configured momentjs locale.
   */
  dateFormat: PropTypes.string,
  /**
   * The links on the instruments.
   *
   * The format of the links: Two formats are recognized. In both cases,
   * any strings are interpereted as an RFC6570 compatible template and
   * interpolated with the columns on the rows of the component.
   *
   * The two accepted inputs are the same as the react-router
   * `to`-prop. Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/location.md).
   */
  linkTo: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      key: PropTypes.string,
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object,
    }),
  ]),
  /**
   * The links that lead to an order details page.
   *
   * The format of the links: Two formats are recognized. In both cases,
   * any strings are interpereted as an RFC6570 compatible template and
   * interpolated with the columns on the rows of the component.
   *
   * The two accepted inputs are the same as the react-router
   * `to`-prop. Either a string or an object.
   *
   * If an object is passed, it is assumed to be a [react-router location
   * descriptor](https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/location.md).
   */
  orderLink: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      key: PropTypes.string,
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
      hash: PropTypes.string,
      state: PropTypes.object,
    }),
  ]),
  /** The filters used to filter the table dataset */
  filters: PropTypes.shape({
    ticker: PropTypes.shape({}),
    orderId: PropTypes.string,
    status: PropTypes.oneOf(['ALL', 'EXPIRED', 'SETTLED', 'CANCELLED'])
      .isRequired,
    fromDate: PropTypes.string.isRequired,
    toDate: PropTypes.string.isRequired,
  }).isRequired,
  /**
   * Maps a status code into a class, used for displaying an icon next to the
   * order in the row. By default, it will use a font-awesome icon if these are
   * available. If they are not, nothing will be rendered.
   */
  getStatusIconClass: PropTypes.func,
  /** Will be passed to the component */
  className: PropTypes.string,
  /** The entrypoint to the api. */
  baseUrl: PropTypes.string.isRequired,
  /** The endpoint used to search for orders. String is interpereted as an RFC6570 compatible template */
  orderSearchUrl: PropTypes.string.isRequired,
  /**
   * Specifies what kind of order to search for.
   */
  type: PropTypes.oneOf(['fund', 'stock', 'fund-and-stock']),
  /** @ignore */
  stockOrders: PropTypes.shape({
    data: PropTypes.array,
    success: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.object,
  }),
  /** @ignore */
  fundOrders: PropTypes.shape({
    data: PropTypes.array,
    success: PropTypes.bool,
    loading: PropTypes.bool,
    error: PropTypes.object,
  }),
};

const serializationFormat = 'YYYY-MM-DD';

export default compose(
  defaultProps(OrderTable.defaultProps),
  fetchHoc(
    ({
      filters,
      dateFormat,
      userId,
      accountId,
      type,
      baseUrl,
      orderSearchUrl,
    }) => {
      const urlTemplate = template.parse(orderSearchUrl);
      if (type !== 'stock' && type !== 'fund-and-stock') return null;
      return urlTemplate.expand({
        baseUrl,
        userId,
        accountId,
        type: 'stock',
        filters: {
          ...filters,
          ticker: filters.ticker ? filters.ticker.ITEM : undefined,
          fromDate: moment(filters.fromDate, dateFormat).format(
            serializationFormat,
          ),
          toDate: moment(filters.toDate, dateFormat).format(
            serializationFormat,
          ),
        },
      });
    },
  ),
  normalize(
    data =>
      data &&
      data.map &&
      data.map(order => ({ ...order, instrumentType: 'stock' })),
  ),
  namespaceFetch('stockOrders'),

  fetchHoc(
    ({
      filters,
      dateFormat,
      userId,
      accountId,
      type,
      baseUrl,
      orderSearchUrl,
    }) => {
      const urlTemplate = template.parse(orderSearchUrl);
      if (type !== 'fund' && type !== 'fund-and-stock') return null;
      return urlTemplate.expand({
        baseUrl,
        userId,
        accountId,
        type: 'fund',
        filters: {
          ...filters,
          isin: filters.ticker ? filters.ticker.ISIN : undefined,
          sector: filters.ticker ? filters.ticker.SECTOR : undefined,
          currency: filters.ticker ? filters.ticker.CURRENCY : undefined,
          fromDate: moment(filters.fromDate, dateFormat).format(
            serializationFormat,
          ),
          toDate: moment(filters.toDate, dateFormat).format(
            serializationFormat,
          ),
        },
      });
    },
  ),
  normalize(
    data =>
      data &&
      data.map &&
      data.map(order => ({ ...order, instrumentType: 'fund' })),
  ),
  namespaceFetch('fundOrders'),
)(OrderTable);
