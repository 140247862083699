import React from 'react';
import PropTypes from 'prop-types';
import { Consumer as ComponentsContext } from '@oms/components-context';
import OrderFetcher from './OrderFetcher';
import OrderDetails from './OrderDetails';

/**
 * **This component is part of the trading components package and requires the corresponding license**
 *
 * A component for fetching and showing details concerning a specified order.
 * This is useful when used in conjunction with the `TransactionsTable`,
 * where clicking an order could bring up the details, e.g. in a modal.
 *
 * `OrderFetcher` does not use `JawsProvider` and is configured via
 * `ComponentsContext`.
 *
 * Unknown props are passed into `OrderDetails`
 * @see See [OrderDetails](/#!/OrderDetails) for the component displaying the details.
 * @see See [OrderFetcher](/#!/OrderFetcher) for the component fetching the details.
 *
 *
 * @since 1.4.0
 */
const OrderComponent = ({
  accountId,
  userId,
  orderId,
  transactionId,
  type,
  onError,
  ...props
}) => (
  <ComponentsContext>
    {({
      ErrorBoundary,
      baseUrl,
      orderUrl,
      orderContractNotesUrl,
      orderTransactionUrl,
      fundTransactionUrl,
    }) => (
      <ErrorBoundary component="OrderComponent" onError={onError}>
        <OrderFetcher
          accountId={accountId}
          userId={userId}
          orderId={orderId}
          transactionId={transactionId}
          type={type}
          baseUrl={baseUrl}
          orderUrl={orderUrl}
          orderContractNotesUrl={orderContractNotesUrl}
          orderTransactionUrl={orderTransactionUrl}
          fundTransactionUrl={fundTransactionUrl}
        >
          {({ data, loading, ...fetcherProps }) =>
            !loading && (
              <OrderDetails
                {...props}
                {...fetcherProps}
                data={data}
                loading={loading}
                instrumentType={type}
              />
            )
          }
        </OrderFetcher>
      </ErrorBoundary>
    )}
  </ComponentsContext>
);

OrderComponent.propTypes = {
  /** The currently logged in user's selected account */
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /** The currently logged in user */
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** The order containing the transaction. Must be provided for stock-transactions. */
  orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The transactionId */
  transactionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /** The type of instrument */
  type: PropTypes.oneOf(['stock', 'fund']).isRequired,
  /**
   * The `onError` prop provides a way to override the default `<ErrorBoundary>`
   * behavior for this component and render a different fallback UI when the
   * component throws an error.
   *
   * @param {Object} errorDescriptor - An object containing details of the error
   * @param {Error} errorDescriptor.error - The error that was thrown
   * @param {Function} errorDescriptor.reset - Resets the error and attempts
   * another render
   * @returns {React.Component} - The fallback UI that is rendered on an error
   */
  onError: PropTypes.func,
};

export default OrderComponent;
