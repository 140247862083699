import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import { compose, defaultProps, withState, withHandlers } from 'recompose';
import { Flex, Box } from '@rebass/emotion';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Trans } from '@lingui/macro';
import { Consumer as ComponentsContext } from '@oms/components-context';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';

import * as styles from './styles';

/* We use nesting, not IDs */
/* eslint-disable jsx-a11y/label-has-for, react/no-unused-prop-types */

/**
 * **This component is part of the trading components package and requires the corresponding license**
 *
 * The filters used in filtering the `TransactionsArchive` table.
 *
 * `onFiltersChange` returns a `filters` shape in the form of:
 *
 * ```js
 * {
 *   orderId: '123',
 *   transactionId: '123',
 *   tradeType: 'BUY',
 *   fromDate: '06/06/2017', // Depends on dateFormat
 *   toDate: '07/06/2017', // Depends on dateFormat
 *   ticker: {
 *     ITEM: 'STL',
 *     SECTOR: 'OSE',
 *     LONG_NAME: 'Statoil',
 *     TYPE: 'EQUITIES'
 *     meta: {
 *       newsweb: true,
 *       company_info: true,
 *       trades: true,
 *       graph_data_tick: true,
 *       financial_calendar: true,
 *       recommendations: false,
 *       substitute_type: '',
 *       brokerstats: true,
 *       dividends: true,
 *       orders: true,
 *       order_log: true,
 *       estimates: false
 *     },
 *   },
 * }
 * ```
 *
 * @see Is a part of the [TransactionsArchive](/#!/TransactionsArchive) component.
 * @since 1.0.0
 */
export const TransactionsFilters = props => {
  const {
    className,
    dateFormat,
    handleChange,
    handleTickerSelected,
    handleTickerCleared,
    handleFromChange,
    handleToChange,
    filters,
    tradeTypeOptions,
    datePickerLocale,
  } = props;
  const { fromDate, toDate } = filters;

  return (
    <fieldset>
      <Flex
        flexWrap={['wrap', 'wrap', 'nowrap']}
        className={className}
        css={styles.transactionsFilters}
        data-testid="TransactionsFilters"
      >
        <Box
          as="label"
          width={[1, 1, 1 / 6]}
          px="8px"
          mt={['15px', '15px', '0px']}
        >
          <Trans>Company ticker</Trans>
          <ComponentsContext>
            {({ Suggest }) => (
              <Suggest
                onSuggestionSelected={handleTickerSelected}
                inputProps={{
                  name: 'ticker',
                }}
                onClear={handleTickerCleared}
              />
            )}
          </ComponentsContext>
        </Box>

        <Box
          as="label"
          width={[1 / 2, 1 / 2, 1 / 6]}
          px="8px"
          mt={['15px', '15px', '0px']}
        >
          <Trans>Order number</Trans>
          <input name="orderId" onChange={handleChange} />
        </Box>

        <Box
          as="label"
          width={[1 / 2, 1 / 2, 1 / 6]}
          px="8px"
          mt={['15px', '15px', '0px']}
        >
          <Trans>Transaction number</Trans>
          <input name="transactionId" onChange={handleChange} />
        </Box>

        <Box
          as="label"
          width={[1, 1, 1 / 6]}
          px="8px"
          mt={['15px', '15px', '0px']}
        >
          <Trans>Type</Trans>
          <Select
            name="tradeType"
            className="TypeSelect Select"
            classNamePrefix="Select"
            onChange={handleChange}
            defaultValue={tradeTypeOptions[0]}
            searchable={false}
            clearable={false}
            options={tradeTypeOptions}
          />
        </Box>

        <Box
          as="label"
          width={[1 / 2, 1 / 2, 1 / 6]}
          px="8px"
          mt={['15px', '15px', '0px']}
        >
          <Trans>From date</Trans>
          <DayPickerInput
            name="fromDate"
            onDayChange={handleFromChange}
            format={dateFormat}
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder={moment(fromDate, dateFormat).format(dateFormat)}
            dayPickerProps={{
              selectedDays: moment(fromDate, dateFormat).toDate(),
              locale: datePickerLocale,
              localeUtils: MomentLocaleUtils,
            }}
          />
        </Box>

        <Box
          as="label"
          width={[1 / 2, 1 / 2, 1 / 6]}
          px="8px"
          mt={['15px', '15px', '0px']}
        >
          <Trans>To date</Trans>
          <DayPickerInput
            name="toDate"
            onDayChange={handleToChange}
            format={dateFormat}
            formatDate={formatDate}
            parseDate={parseDate}
            placeholder={moment(toDate, dateFormat).format(dateFormat)}
            dayPickerProps={{
              selectedDays: moment(toDate, dateFormat).toDate(),
              locale: datePickerLocale,
              localeUtils: MomentLocaleUtils,
            }}
          />
        </Box>
      </Flex>
    </fieldset>
  );
};

TransactionsFilters.defaultProps = {
  dateFormat: 'L',
  debounceTime: 150,
  className: 'TransactionsFilters',
  datePickerLocale: 'en',
  tradeTypeOptions: [
    { value: undefined, label: <Trans>All</Trans> },
    { value: 'BUY', label: <Trans>Buy</Trans> },
    { value: 'SELL', label: <Trans>Sale</Trans> },
  ],
};

TransactionsFilters.propTypes = {
  /** The function that is called whenever the filters are changed */
  onFiltersChange: PropTypes.func.isRequired,
  /**
   * A momentjs format string that the date picker will use. Default of `L` is
   * a localized short format based on the configured momentjs locale.
   */
  dateFormat: PropTypes.string,
  /**
   * The time to delay each keystroke before dispatching an `onFiltersChange`
   */
  debounceTime: PropTypes.number,
  /** Will be passed to the component */
  className: PropTypes.string,
  /** The options the `<Select>` renders out */
  tradeTypeOptions: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.node }),
  ),
  /**
   * A locale code of the language that will be used in the date picker.
   * English is used by default. Keep in mind you need to make sure moment has
   * the required locale data by importing the locale files into the project,
   * for example `import 'moment/locale/sv';`
   */
  datePickerLocale: PropTypes.string,

  /** @ignore */
  handleChange: PropTypes.func.isRequired,
  /** @ignore */
  handleTickerSelected: PropTypes.func.isRequired,
  /** @ignore */
  handleTickerCleared: PropTypes.func.isRequired,
  /** @ignore */
  handleFromChange: PropTypes.func.isRequired,
  /** @ignore */
  handleToChange: PropTypes.func.isRequired,
  /** @ignore */
  filters: PropTypes.shape({}).isRequired,
};

export const buildDefaultFilters = ({ dateFormat } = {}) => ({
  orderId: undefined,
  transactionId: undefined,
  tradeType: undefined,
  fromDate: moment()
    .subtract(1, 'month')
    .format(dateFormat || TransactionsFilters.defaultProps.dateFormat),
  toDate: moment().format(
    dateFormat || TransactionsFilters.defaultProps.dateFormat,
  ),
});

export default compose(
  defaultProps(TransactionsFilters.defaultProps),
  withState('filters', 'updateFilters', buildDefaultFilters),
  // Build debounce handler from initialProps so that debounce function is
  // constructed properly
  withHandlers(initialProps => {
    const debouncedUpdate = debounce(props => {
      const { onFiltersChange, filters } = props;
      onFiltersChange(filters);
    }, initialProps.debounceTime);

    return {
      debouncedUpdate: props => value => debouncedUpdate(props, value),
    };
  }),
  withHandlers({
    handleChange: props => (event, option) => {
      const { updateFilters, debouncedUpdate } = props;
      let name;
      let value;

      if (event.target) {
        ({ name, value } = event.target);
      } else {
        ({ name } = option);
        ({ value } = event);
      }

      updateFilters(
        state => ({
          ...state,
          [name]: value,
        }),
        debouncedUpdate,
      );
    },

    // The following need their own handlers to avoid inline functions in render
    handleTickerSelected: props => (event, value) => {
      const { updateFilters, debouncedUpdate } = props;

      updateFilters(
        state => ({
          ...state,
          ticker: value.suggestion,
        }),
        debouncedUpdate,
      );
    },
    handleTickerCleared: props => () => {
      const { updateFilters, debouncedUpdate } = props;

      updateFilters(
        state => ({ ...state, ticker: undefined }),
        debouncedUpdate,
      );
    },
    handleFromChange: props => value => {
      const { updateFilters, dateFormat, debouncedUpdate } = props;

      updateFilters(
        state => ({
          ...state,
          fromDate: moment(value).format(dateFormat),
        }),
        debouncedUpdate,
      );
    },
    handleToChange: props => value => {
      const { updateFilters, dateFormat, debouncedUpdate } = props;

      updateFilters(
        state => ({
          ...state,
          toDate: moment(value).format(dateFormat),
        }),
        debouncedUpdate,
      );
    },
  }),
)(TransactionsFilters);
