import React from 'react';
import PropTypes from 'prop-types';
import { Consumer as ComponentsContext } from '@oms/components-context';
import { compose, defaultProps, withState } from 'recompose';

import OrderFilters, { buildDefaultFilters } from './OrderFilters';
import OrderTable from './OrderTable';

/**
 * **This component is part of the trading components package and requires the corresponding license**
 *
 * A component which lists the currently logged in user's previous and current
 * orders.
 * Includes filters for searching and filtering the result set.
 *
 * Unknown props are spread into `OrderTable`.
 *
 * @see See [OrderFilters](/#!/OrderFilters) for the filter component.
 * @see See [OrderTable](/#!/OrderTable) for the table component.
 * @since 1.0.0
 */
export const OrderArchive = props => {
  const {
    className,
    filters,
    userId,
    updateFilters,
    filterProps,
    ...rest
  } = props;

  return (
    <ComponentsContext>
      {({ baseUrl, orderSearchUrl }) => (
        <section className={className}>
          <OrderFilters onFiltersChange={updateFilters} {...filterProps} />
          <OrderTable
            filters={filters}
            userId={userId}
            baseUrl={baseUrl}
            orderSearchUrl={orderSearchUrl}
            {...rest}
          />
        </section>
      )}
    </ComponentsContext>
  );
};

OrderArchive.defaultProps = {
  dateFormat: 'L',
  className: 'OrderArchive',
  type: 'fund-and-stock',
};

OrderArchive.propTypes = {
  /** The currently logged in user */
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** The currently logged in user's selected account */
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /**
   * Props that are passed to the `OrderFilters` component.
   *
   * @see See `OrderFilters` for available props
   */
  filterProps: PropTypes.shape({}),
  /**
   * A momentjs format string that the date picker will use. Default of `L` is
   * a localized short format based on the configured momentjs locale.
   */
  dateFormat: PropTypes.string,
  /** Will be passed to the component */
  className: PropTypes.string,
  /**
   * Specifies what kind of transactions to search for.
   */
  type: PropTypes.oneOf(['fund', 'stock', 'fund-and-stock']),

  /** @ignore */
  filters: PropTypes.shape({}),
  /** @ignore */
  updateFilters: PropTypes.func.isRequired,
};

export default compose(
  defaultProps(OrderArchive.defaultProps),
  withState('filters', 'updateFilters', buildDefaultFilters),
)(OrderArchive);
