import { mapProps } from 'recompose';

const namespaceResponse = name => props => {
  const { data, error, loading, success, ...rest } = props;

  return {
    [name]: { data, error, loading, success },
    ...rest,
  };
};

export default name => mapProps(namespaceResponse(name));
