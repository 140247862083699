import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';

const focusButton = css`
  :not(:focus) {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
  }
`;

export const FocusButton = props => {
  const { children } = props;

  return (
    <button type="button" css={focusButton} {...props}>
      {children}
    </button>
  );
};

FocusButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

FocusButton.defaultProps = {
  className: 'OmsFocusButton',
};

export default FocusButton;
