import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import { NoData } from '@oms/components';
import { buildURL } from './utils';

/**
 * An error that is thrown in in the context of data fetching for the component
 */
export class FetchError extends Error {
  constructor({ message, method, response }, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent
    // constructor
    super(message, ...params);

    // Maintains proper stack trace for where our error was thrown (only
    // available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = 'FetchError';
    this.componentName = 'OrderFetcher';
    this.message = message;
    this.method = method;
    this.response = response;
  }
}

/**
 * **This component is part of the trading components package and requires the corresponding license**
 *
 * This is a Wrapper component that fetches details on a specified order.
 * This allows implementors to build their own UI using the same dataset as
 * `OrderDetails` uses.
 *
 * This component uses the function-as-child pattern, see the children prop.
 *
 */
export class OrderFetcher extends React.Component {
  state = {
    loading: false,
    data: undefined,
    error: undefined,
  };

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prev) {
    const { userId, accountId, orderId } = this.props;

    const userIdChanged = userId && userId !== prev.userId;
    const accountIdChanged = accountId && accountId !== prev.accountId;
    const orderIdChanged = orderId && orderId !== prev.orderId;

    if (userIdChanged || accountIdChanged || orderIdChanged) {
      this.initialize();
    }
  }

  initialize = async () => {
    try {
      await this.fetchOrderDetails();
    } catch (error) {
      this.setState({ error });
    }
  };

  fetchOrderDetails = async () => {
    const {
      userId,
      accountId,
      orderId,
      transactionId,
      baseUrl,
      orderUrl: orderTemplate,
      orderContractNotesUrl: contractNotesTemplate,
      orderTransactionUrl: stockTransactionTemplate,
      fundTransactionUrl: fundTransactionTemplate,
      type,
    } = this.props;

    this.setState({ loading: true });

    const transactionTemplate =
      type === 'fund' ? fundTransactionTemplate : stockTransactionTemplate;

    const orderUrl = buildURL(orderTemplate, {
      baseUrl,
      orderId,
      userId,
      accountId,
      type,
    });

    const transactionUrl = buildURL(transactionTemplate, {
      baseUrl,
      orderId,
      userId,
      accountId,
      transactionId,
      type,
    });

    const contractNotesUrl = buildURL(contractNotesTemplate, {
      baseUrl,
      orderId,
      userId,
      accountId,
      type,
    });

    const urlsToFetch =
      type === 'stock'
        ? [transactionUrl, orderUrl, contractNotesUrl]
        : [transactionUrl];

    const [
      transactionReponse,
      orderResponse,
      contractNotesResponse,
    ] = await Promise.all(urlsToFetch.map(url => fetch(url)));

    // If transactionResponse (most important data) failed, throw FetchError
    if (!transactionReponse.ok) {
      this.setState({ loading: false });
      throw new FetchError({
        message: `Fetching transaction details failed. ${await transactionReponse.text()}`,
        method: 'fetchOrderDetails',
        transactionReponse,
      });
    }

    const [
      transactionData = {},
      orderData = {},
      contractNotes = [],
    ] = await Promise.all([
      transactionReponse.ok ? transactionReponse.json() : undefined,
      orderResponse && orderResponse.ok ? orderResponse.json() : undefined,
      contractNotesResponse && contractNotesResponse.ok
        ? contractNotesResponse.json()
        : undefined,
    ]);

    this.setState({
      data: {
        ...orderData,
        transactionData,
        contractNotes,
      },
      loading: false,
    });
  };

  render() {
    const { data, loading, error } = this.state;
    const { children } = this.props;

    if (error) throw error;

    if (!data && !loading) return <NoData text={t`No order details found`} />;

    return children({ data, loading });
  }
}

OrderFetcher.propTypes = {
  /** The currently logged in user's selected account */
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /** The currently logged in user */
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** The orderId to fetch details for. Must be provided for stock-transactions */
  orderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The orderId to fetch details for */
  transactionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  /**
   * The entrypoint to the api.
   * We recommend using the ComponentsContext for this.
   * */
  baseUrl: PropTypes.string.isRequired,
  /**
   * The endpoint used to fetch data on order.
   * String is interpereted as an RFC6570 compatible template
   * We recommend using the ComponentsContext for this.
   * */
  orderUrl: PropTypes.string.isRequired,
  /**
   * The endpoint used to fetch contract notes on a stock order.
   * String is interpereted as an RFC6570 compatible template
   * We recommend using the ComponentsContext for this.
   * */
  orderContractNotesUrl: PropTypes.string.isRequired,
  /**
   * The endpoint used to fetch transaction data on order.
   * String is interpereted as an RFC6570 compatible template
   * We recommend using the ComponentsContext for this.
   * */
  orderTransactionUrl: PropTypes.string.isRequired,
  /**
   * The endpoint used to fetch fund-transaction data on order.
   * String is interpereted as an RFC6570 compatible template
   * We recommend using the ComponentsContext for this.
   * */
  fundTransactionUrl: PropTypes.string.isRequired,
  /**
   * Specifies what kind of order it's fetching data for.
   */
  type: PropTypes.oneOf(['fund', 'stock']).isRequired,
  /**
   * A render function that exposes the results as well as metadata on the
   * query.

   * @param {Object} renderProps - The object containing the returned values
   * @param {Object} renderProps.data - The object containing the returned order
   * @param {bool} renderProps.loading - True while fetching data
  */
  children: PropTypes.func.isRequired,
};

export default OrderFetcher;
